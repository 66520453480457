import {cond, pipe, match, isEmpty, not, always} from 'ramda';

const getDeliveryType = cond([
  [
    pipe(
      match(
        /cdek|russianpost|tickets/,
      ),
      isEmpty,
      not,
    ),
    always('online'),
  ],
  [
    pipe(
      match(/stadium/),
      isEmpty,
      not,
    ),
    always('offline'),
  ],
]);

export default getDeliveryType;
