import * as R from "ramda";
import React from 'react';
import b from './Layout.module.scss';
import {Link, Outlet} from "react-router-dom";
import {useLocation} from 'react-router-dom';
import {ReactComponent as Logo} from './assets/logo.svg';
import HeaderButton from "../../components/headerButton/HeaderButton";
import Modal from "../../components/modal/Modal";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classNames from 'classnames/bind';
import {getOrderId, getOrderName} from "../../state/order";
import {connect} from "react-redux";
import ModalAlert from "../../components/modal/ModalAlert";

let cx = classNames.bind(b);

const Layout = ({orderId, name}) => {
  const {pathname} = useLocation();
  // let page = 'onboard';
  let page = 'finish';
  const path = pathname.replace(/[^a-zA-Z]+/g, '');
  if (path.length > 0) {
    page = path;
  }
  return (
    <div className={cx({
      [page]: true,
      generalPage: true,
      showModal: orderId
    })}>
      <div className={b.navigationContainer}>
        <div className={`${b.navigation} row`}>
          <div className="large-2 column">
            <Link draggable={false} to='/'>
              <span className={b.logo}><Logo/></span>
            </Link>
          </div>
          <div className="large-offset-7 large-3 column">
            <span className={b.headerButton}>
              <HeaderButton  href="https://winline.ru"><span className="show-for-medium">На сайт</span> winline.ru</HeaderButton>
            </span>
          </div>
        </div>
      </div>
      <main className={`page ${page}`}>
        <Outlet/>
      </main>
      {orderId && (
        <Modal />
      )}
      {/*{page === 'team' && <ModalAlert />}*/}
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = R.applySpec({
  orderId: getOrderId,
  name: getOrderName,
});

export default connect(mapStateToProps)(Layout);
