import {createAction} from 'redux-actions';

// Action Types
export const REQUEST_ORDER = 'order/REQUEST_ORDER';
export const RECEIVE_ORDER = 'order/RECEIVE_ORDER';
export const REQUEST_ORDER_FAILED = 'order/REQUEST_ORDER_FAILED';
export const SET_FORM_FIELD = 'order/SET_FORM_FIELD';
export const SET_ORDER_TYPE = 'order/SET_ORDER_TYPE';
export const RESET_ORDER = 'order/RESET_ORDER';
export const RESET_HIDDEN_FIELDS = 'order/RESET_HIDDEN_FIELDS';

// Action Creators
export const requestOrder = createAction(REQUEST_ORDER);
export const receiveOrder = createAction(RECEIVE_ORDER);
export const receiveOrderFailed = createAction(REQUEST_ORDER_FAILED);
export const setFormField = createAction(SET_FORM_FIELD);
export const setOrderType = createAction(SET_ORDER_TYPE);
export const resetOrder = createAction(RESET_ORDER);
export const resetHiddenFields = createAction(RESET_HIDDEN_FIELDS);
