import {all, call} from 'redux-saga/effects';
import {teamSaga} from "./state/team";
import {goodSaga} from "./state/good";
import {promocodeSaga} from "./state/promocode";
import {orderSaga} from "./state/order";
import {suggestsSaga} from "./state/suggests";

const rootSaga = function* () {
  yield all([
    call(teamSaga),
    call(goodSaga),
    call(promocodeSaga),
    call(suggestsSaga),
    call(orderSaga)
  ]);
};

export default rootSaga;
