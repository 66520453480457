import * as R from 'ramda';

// Команды
export const getTeamState = R.prop('team');

// Товары
export const getGoodState = R.prop('good');

// Промокоды
export const getPromocodeState = R.prop('promocode');

// Подсказки по адресам
export const getSuggestsState = R.prop('suggests');

// Заказ
export const getOrderState = R.prop('order');
