import {always, applySpec, cond, converge, equals, filter, find, map, path, pipe, prop, propEq} from "ramda";
import {getGoods} from "../good";
import {getSelectedGoodId} from "../promocode";
import {getOrderState} from "../../module-state-selectors";
import getDeliveryType from "../../utils/getDeliveryType";

export const getOrderGood = converge(
  (entities, selectedId) => find(propEq('id', selectedId), entities),
  [getGoods, getSelectedGoodId],
);
export const getIsFetching = pipe(getOrderState, prop('isFetching'));
export const getOrderId = pipe(getOrderState, prop('orderId'));
export const getOrderValues = pipe(getOrderState, prop('values'))
export const getOrderName = pipe(getOrderState, path(['values', 'name']))
export const getOrderType = pipe(getOrderState, prop('orderType'))
export const getModificationsForSelect = converge(
  (good, delivery) => pipe(
    prop('variants'),
    filter(variant => {
      const deliveryType = getDeliveryType(delivery);
      const propEqDeliveryType = pipe(
        cond([
          [equals('online'), always('variantInStockOnline')],
          [equals('offline'), always('variantInStockOffline')],
        ])
      )(deliveryType);
      return propEq(propEqDeliveryType, true)(variant);
    }),
    map(
      applySpec({
        value: prop('variantId'),
        label: prop('variantValue'),
      })
    ),
  )(good),
  [getOrderGood, getOrderType],
);
