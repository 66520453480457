import * as R from 'ramda';
import {handleAction, handleActions} from 'redux-actions';
import {combineReducers} from "redux";
import {EMPTY_GOODS, RECEIVE_GOODS, REQUEST_GOODS, SELECT_GOOD} from "./actions";

const entities = handleActions(
  {
    [EMPTY_GOODS]: R.empty,
    [RECEIVE_GOODS]: (state, {payload}) => R.uniqBy(R.prop('id'), [...payload, ...state])
  },
  [],
);

const isFetching = handleActions(
  {[REQUEST_GOODS]: R.T, [RECEIVE_GOODS]: R.F},
  false,
);

const selectedId = handleAction(SELECT_GOOD, (_, {payload}) => payload, null);

export default combineReducers({
  entities,
  isFetching,
  selectedId
});
