import {isEmpty} from "ramda";
import {call, put, takeLatest, select} from 'redux-saga/effects';
import {apiCall} from "./manager";
import handleError from "../../utils/handleError";
import {receiveOrder, receiveOrderFailed, REQUEST_ORDER} from "./actions";
import {getEnteredCode, getEntry as getPromocodeInfo} from "../promocode";
import {toast} from "react-toastify";
import {getOrderType} from "./selectors";

const fetchOrderSaga = function* ({payload}) {
  try {
    const {id, goodId} = yield select(getPromocodeInfo);
    const promocode = yield select(getEnteredCode);
    const orderType = yield select(getOrderType);
    const {lastname, name, patronymic, email, phone, comment, goodVariantId} = payload;
    const defaultDataOrder = {
      goodId: goodId,
      goodVariantId: isEmpty(goodVariantId) ? null : goodVariantId,
      promocodeId: id,
      promocode: promocode,
      type: orderType,
      comment: comment,
      customer_lastname: lastname,
      customer_name: name,
      customer_patronymic: patronymic,
      customer_email: email,
      customer_phone: phone,
    };
    let deliveryData;
    if (orderType === 'stadium') {
      const {stadium} = payload;
      deliveryData = {
        delivery_stadium: stadium
      }
    }
    else if (orderType === 'tickets') {
      const {fanid} = payload;
      deliveryData = {
        fan_id: fanid
      }
    }
    else {
      const {address, postalcode, kladr, region, city, street, stead, house, block, floor, flat} = payload;
      deliveryData = {
        delivery_address: address,
        delivery_postalcode: postalcode,
        delivery_kladr: kladr,
        delivery_region: region,
        delivery_city: city,
        delivery_street: street,
        delivery_stead: stead,
        delivery_house: house,
        delivery_block: block,
        delivery_floor: floor,
        delivery_flat: flat,
      }
    }
    const orderId = yield call(apiCall, {...defaultDataOrder, ...deliveryData});
    yield put(receiveOrder(orderId));
  } catch (error) {
    const handledError = handleError(error);
    yield put(receiveOrderFailed(handledError))
    yield toast.error(handledError.message);
  }
}

const orderSaga = function* () {
  yield takeLatest(REQUEST_ORDER, fetchOrderSaga);
}

export default orderSaga;
