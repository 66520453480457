import React from 'react';
import {Link, useLocation} from "react-router-dom";
import b from './Button.module.scss';
import classNames from 'classnames/bind';

let cx = classNames.bind(b);

const Button = (props) => {
  const prevRoute = useLocation();
  const {
    type = 'link',
  } = props;
  switch (type) {
    case 'externalLink':
      return <ButtonExternalLink {...props} />
    case 'submit':
      return <ButtonSubmit {...props} />
    default:
      props = {prevRoute, ...props};
      return <ButtonLink {...props} />
  }
}

const ButtonExternalLink = (props) => {
  const {to, size = 'normal', expanded = false, disabled = false} = props;
  return (
    <ButtonContainer expanded={expanded}>
      <a rel="noreferrer" target={"_blank"} draggable={false} href={to} className={cx({
        btn: true,
        [`btn-${size}`]: true,
        disabled,
        expanded
      })}>
          <span className={b.text}>
          {props.children}
        </span>
      </a>
    </ButtonContainer>
  )
}

const ButtonLink = (props) => {
  const {
    to,
    size = 'normal',
    disabled = false,
    expanded = false,
    label = false,
    labelText = "",
    prevRoute
  } = props;
  return (
    <ButtonContainer expanded={expanded} label={label} labelText={labelText}>
      <Link to={to} state={{prevRoute}} draggable={false} className={cx({
        btn: true,
        [`btn-${size}`]: true,
        disabled,
        expanded
      })}>
        <span className={b.text}>
          {props.children}
        </span>
      </Link>
    </ButtonContainer>
  );
}

const ButtonSubmit = (props) => {
  const {
    size = 'normal',
    disabled = false,
    expanded = false,
    label = false,
    labelText = "",
  } = props;
  return (
    <ButtonContainer expanded={expanded} label={label} labelText={labelText}>
      <button type="submit" className={cx({
        btn: true,
        [`btn-${size}`]: true,
        disabled,
        expanded
      })}>
        <span className={b.text}>
          {props.children}
        </span>
      </button>
    </ButtonContainer>
  );
}

const ButtonContainer = (props) => {
  const {expanded = false, label = false, labelText = ""} = props;
  return (
    <div className={cx({
      linkContainer: true,
      expanded
    })}>
      {props.children}
      {label && (
        <span className={b.labelText}>{labelText}</span>
      )}
    </div>
  );
}

export default Button;
