import React from 'react';
import {useMediaQuery} from 'react-responsive'
import b from './Finish.module.scss'
import Button from "../../components/button/Button";
import {ReactComponent as IconFeature} from './assets/ic-feature-magic.svg';

const Finish = () => {
  return (
    <div className="row">
      <div className="small-12 medium-10 medium-offset-1 large-offset-0 large-6 column small-m-t-60 medium-m-t-80">
        <h3 className={b.headline}>
          Проект завершен
        </h3>
      </div>
      <div className="small-12 medium-10 medium-offset-1 large-offset-0 large-6 column small-p-t-80 medium-p-t-100">
        <div className={b.featuresHeadline}>
          Благодарим за интерес к компании Winline, не пропустите новые интересные акции!
        </div>
      </div>
    </div>
  );
}

export default Finish;
