import {call, put, takeLatest} from 'redux-saga/effects';
import {receiveTeams, REQUEST_TEAMS} from './actions';
import {apiCall} from "./manager";

const fetchTeamsSaga = function* () {
  const teams = yield call(apiCall);
  yield put(receiveTeams(teams));
};

const teamsSaga = function* () {
  yield takeLatest(REQUEST_TEAMS, fetchTeamsSaga);
};

export default teamsSaga;
