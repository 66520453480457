import * as R from "ramda";
import {call, put, takeLatest, select} from 'redux-saga/effects';
import {apiCall} from "./manager";
import {apiCall as apiCallTeams} from "../team/manager";
import {emptyGoods, receiveGoods, REQUEST_GOODS} from './actions';
import {getSelectedId as getSelectedTeamId, receiveTeams, selectTeam} from "../team";

const fetchGoodsSaga = function* ({payload}) {
  let selectedTeamId = yield select(getSelectedTeamId);
  if (R.isNil(selectedTeamId)) {
    const teams = yield call(apiCallTeams);
    yield put(receiveTeams(teams));
    yield put(selectTeam(parseInt(payload)));
    selectedTeamId = payload;
  }
  yield put(emptyGoods());
  const goods = yield call(apiCall, selectedTeamId);
  yield put(receiveGoods(goods));
};

const goodsSaga = function* () {
  yield takeLatest(REQUEST_GOODS, fetchGoodsSaga);
};

export default goodsSaga;
