const HOSTNAME = process.env.REACT_APP_API_URL;

export const ENDPOINTS = {
  // Команды
  teams: HOSTNAME + '/api/v1/teams/getTeams',
  goods: teamId => HOSTNAME + `/api/v1/goods/getGoods?teamId=${teamId}`,
  applyPromocode: HOSTNAME + '/api/v1/promocode/applyPromocode',
  createOrder: HOSTNAME + '/api/v1/order/createOrder',
  suggests: search => HOSTNAME + `/api/v1/order/getSuggestAddresses?search=${search}`,
}
