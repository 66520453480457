import {prop, path, pipe, map, applySpec} from 'ramda';
import {ENDPOINTS} from '../../api';
import axios from "redaxios";

const getGoods = list =>
  pipe(
    prop('data'),
    map(
      applySpec({
        id: prop('goodId'),
        goodType: prop('goodType'),
        teamId: prop('teamId'),
        name: prop('goodName'),
        price: prop('price'),
        vendorCode: prop('vendorCode'),
        photo: path(['photo', 'url']),
        variants: prop('variants'),
        inStockOnline: prop('inStockOnline'),
        inStockOffline: prop('inStockOffline'),
      }),
    ),
  )(list);

export const apiCall = (teamId) => {
  return axios.get(ENDPOINTS.goods(teamId),
  ).then(response => {
    return getGoods(response.data);
  })
    .catch(err => {
      throw err;
    });
}
