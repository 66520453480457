import {createAction} from 'redux-actions';

// Action Types
export const SELECT_SPORT_TYPE = 'team/SELECT_SPORT_TYPE';
export const REQUEST_TEAMS = 'team/REQUEST_TEAMS';
export const RECEIVE_TEAMS = 'team/RECEIVE_TEAMS';
export const SELECT_TEAM = 'team/SELECT_TEAM';

// Action Creators
export const selectSportType = createAction(SELECT_SPORT_TYPE);
export const requestTeams = createAction(REQUEST_TEAMS);
export const receiveTeams = createAction(RECEIVE_TEAMS);
export const selectTeam = createAction(SELECT_TEAM);
