import * as R from 'ramda';
import {handleActions} from 'redux-actions';
import {combineReducers} from "redux";
import {EMPTY_SUGGEST, RECEIVE_SUGGEST, REQUEST_SUGGEST} from "./actions";

const entities = handleActions(
  {
    [EMPTY_SUGGEST]: () => [],
    [RECEIVE_SUGGEST]: (state, {payload}) => R.uniqBy(R.prop('value'), [...payload, ...state])
  },
  [],
);

const isFetching = handleActions(
  {
    [REQUEST_SUGGEST]: R.T,
    [RECEIVE_SUGGEST]: R.F
  },
  false,
);

export default combineReducers({
  entities,
  isFetching,
});
