import React, {useEffect} from 'react';
import b from './Modal.module.scss'
import {Link} from "react-router-dom";
import {ReactComponent as IconCloseButton} from "./assets/ic-close.svg";
import {connect} from "react-redux";
import * as R from "ramda";
import {getOrderId, getOrderName, getOrderType, resetOrder} from "../../state/order";
import {removePromocode} from "../../state/promocode";

const Modal = ({orderId, orderType, name, resetOrder, removePromocode}) => {
  useEffect(() => {
    document.body.classList.add('modal-active');
    return () => {
      document.body.classList.remove('modal-active');
    }
  }, []);

  const onCloseHandler = () => {
    resetOrder();
    removePromocode();
  }

  let orderMessage;
  if (orderType === 'cdek') {
    orderMessage = "В течение 48 часов вы получите уведомление с номером для отслеживания на указанный вами адрес электронной почты!";
  } else if (orderType === 'stadium') {
    orderMessage = "Забрать товар вы сможете в специальной точке выдачи Winline на домашнем стадионе любимой команды. Для этого покажите номер заказа сотруднику на стойке.";
  } else if (orderType === 'tickets') {
    orderMessage = "После обработки заказа ваши билеты в течение 1-7 дней будут отправлены на указанную электронную почту.";
  }

  return (
    <div className={b.modal}>
      <div className={b.modalButtonContainer}>
        <Link onClick={onCloseHandler} to='/magic' className={b.modalButton}>
          <IconCloseButton />
        </Link>
      </div>
      <div className={b.modalInner}>
        <div className={b.modalHeadline}>
          Заказ успешно
          <span>оформлен!</span>
        </div>
        <div className={b.modalSubContent}>
          Номер заказа:
          <span>{orderId}</span>
        </div>
        <div className={b.modalContent}>
          <p>
            {name ? `Спасибо, ${name}` : 'Спасибо'}! Заявка принята. <br/>
            {orderMessage}
          </p>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  resetOrder,
  removePromocode,
};

const mapStateToProps = R.applySpec({
  orderId: getOrderId,
  orderType: getOrderType,
  name: getOrderName,
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
