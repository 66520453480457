import React from 'react';
import {useMediaQuery} from 'react-responsive'
import b from './Magic.module.scss'
import Button from "../../components/button/Button";
import {ReactComponent as IconFeature} from './assets/ic-feature-magic.svg';

const Magic = () => {
  const isMobile = useMediaQuery({maxWidth: 639});
  return (
    <div className="row">
      <div className="small-12 medium-10 medium-offset-1 large-offset-0 large-6 column small-m-t-60 medium-m-t-80">
        <h3 className={b.headline}>
          Магазин впечатлений
        </h3>
        <div className={`${b.buttonLink}`}>
          <Button to="https://m.winline.ru/favteam_shop" type='externalLink' size="large" expanded={isMobile}>Перейти в магазин</Button>
        </div>
      </div>
      <div className="small-12 medium-10 medium-offset-1 large-offset-0 large-6 column small-p-t-80 medium-p-t-100">
        <div className={b.featuresHeadline}>
          Спасибо, что ты с нами! Болей за своих и не забывай про крутые впечатления!
        </div>
        <div className={b.featuresContainer}>
          <ul className={b.features}>
            <li className={b.feature}>
              <div className={b.featureInner}>
                <span className={b.feautureIcon}><IconFeature/></span>
                <span className={b.featureText}>Лучшие места <br className='show-for-medium'/> и обслуживание</span>
              </div>
            </li>
            <li className={b.feature}>
              <div className={b.featureInner}>
                <span className={b.feautureIcon}><IconFeature/></span>
                <span className={b.featureText}>Настоящий <br className='show-for-medium'/> контракт</span>
              </div>
            </li>
            <li className={`${b.feature}`}>
              <div className={b.featureInner}>
                <span className={b.feautureIcon}><IconFeature/></span>
                <span className={b.featureText}>Вечер наедине <br className='show-for-medium'/> с кумиром</span>
              </div>
            </li>
            <li className={`${b.feature}`}>
              <div className={b.featureInner}>
                <span className={b.feautureIcon}><IconFeature/></span>
                <span className={b.featureText}>Путешествие <br className='show-for-medium'/> с командой</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Magic;
