import React from "react";
import b from './HeaderButton.module.scss';
import {ReactComponent as Logo} from './assets/arrow-right.svg';

const HeaderButton = (props) => {
  const {href} = props;
  // b.disabled - неактивное состояние
  return <a className={`${b.containerLink}`} rel="noreferrer" target="_blank" href={href}>
    <span className={b.container}>
      <span className={b.text}>{props.children}</span>
      <span className={b.icon}><Logo/></span>
    </span>
  </a>
}

export default HeaderButton;
