import * as R from 'ramda';
import {getTeamState} from '../../module-state-selectors';

export const getSelectedSportTypeId = R.pipe(getTeamState, R.prop('selectedSportTypeId'));
export const getIsFetching = R.pipe(getTeamState, R.prop('isFetching'));
export const getSelectedId = R.pipe(getTeamState, R.prop('selectedId'));
export const getTeams = R.pipe(getTeamState, R.prop('entities'));
export const getFilteredTeams = R.converge(
  (entities, sportTypeId) => {
    if (R.isNil(sportTypeId)) {
      return R.identity(entities)
    } else {
      return R.filter(R.propEq('typeId', sportTypeId), entities)
    }
  },
  [getTeams, getSelectedSportTypeId],
);
export const getSelectedTeam = R.converge(
  (entities, selectedId) => R.find(R.propEq('id', selectedId), entities),
  [getTeams, getSelectedId],
);
export const getSportTypes = R.pipe(
  getTeams,
  R.map(
    R.applySpec({
      type: R.prop('typeName'),
      id: R.prop('typeId'),
    }),
  ),
  R.uniq
)
