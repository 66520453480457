import {prop, path, pipe, applySpec} from 'ramda';

const handleError = error => pipe(
  path(['data']),
  applySpec({
    errorHeader: prop('code'),
    message: prop('message'),
  }),
)(error);

export default handleError;
