import {call, put, takeLatest} from 'redux-saga/effects';
import {receiveSuggest, REQUEST_SUGGEST} from './actions';
import {apiCall} from "./manager";

const fetchSuggestsSaga = function* ({payload}) {
  try {
    const suggests = yield call(apiCall, payload);
    yield put(receiveSuggest(suggests));
  }
  catch(error) {
    // todo Возможно надо обработать ошибки
  }
};

const suggestsSaga = function* () {
  yield takeLatest(REQUEST_SUGGEST, fetchSuggestsSaga);
};

export default suggestsSaga;
