import * as R from 'ramda';
import {ENDPOINTS} from '../../api';
import axios from "redaxios";

const getPromocode = promocode => R.pipe(
  R.prop('data'),
  R.applySpec({
    id: R.prop('promocodeId'),
    teamId: R.propOr(null, 'teamId'),
    goodId: R.prop('goodId'),
    points: R.prop('points'),
  }),
)(promocode);

export const apiCall = (payload, selectedTeamId) => {
  return axios.post(ENDPOINTS.applyPromocode, {promocode: payload, team: selectedTeamId}
  ).then(response => {
    return getPromocode(response.data);
  })
    .catch(err => {
      throw err;
    });
}
