import {createAction} from 'redux-actions';

// Action Types
export const REQUEST_SUGGEST = 'suggest/REQUEST_SUGGEST';
export const RECEIVE_SUGGEST = 'suggest/RECEIVE_SUGGEST';
export const EMPTY_SUGGEST = 'suggest/EMPTY_SUGGEST';

// Action Creators
export const requestSuggest = createAction(REQUEST_SUGGEST);
export const receiveSuggest = createAction(RECEIVE_SUGGEST);
export const clearSuggest = createAction(EMPTY_SUGGEST);
