import * as R from 'ramda';
import {ENDPOINTS} from '../../api';
import axios from "redaxios";

const getTeams = list =>
  R.pipe(
    R.prop('data'),
    R.map(
      R.applySpec({
        id: R.prop('teamId'),
        name: R.prop('teamName'),
        city: R.prop('teamCity'),
        cover: R.path(['teamLogo', 'url']),
        logo: R.path(['teamLogoHover', 'url']),
        stadiumName: R.prop('teamStadiumName'),
        stadiumAddress: R.prop('teamStadiumAddress'),
        stadiumDelivery: R.prop('teamStadiumDelivery'),
        typeId: R.path(['type', 'typeId']),
        typeName: R.path(['type', 'typeName']),
      }),
    ),
  )(list);

export const apiCall = () => {
  return axios.get(ENDPOINTS.teams,
  ).then(response => {
    return getTeams(response.data);
  })
    .catch(err => {
      throw err;
    });
}
