import {createAction} from 'redux-actions';

// Action Types
export const REQUEST_PROMOCODE = 'promocode/REQUEST_PROMOCODE';
export const RECEIVE_PROMOCODE = 'promocode/RECEIVE_PROMOCODE';
export const REQUEST_PROMOCODE_FAILED = 'promocode/REQUEST_PROMOCODE_FAILED';
export const REMOVE_PROMOCODE = 'promocode/REMOVE_PROMOCODE';

// Action Creators
export const requestPromocode = createAction(REQUEST_PROMOCODE);
export const receivePromocode = createAction(RECEIVE_PROMOCODE);
export const receivePromocodeFailed = createAction(REQUEST_PROMOCODE_FAILED);
export const removePromocode = createAction(REMOVE_PROMOCODE);
