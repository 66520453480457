import {createAction} from 'redux-actions';

// Action Types
export const REQUEST_GOODS = 'good/REQUEST_GOODS';
export const RECEIVE_GOODS = 'good/RECEIVE_GOODS';
export const EMPTY_GOODS =  'good/EMPTY_GOODS';
export const SELECT_GOOD = 'good/SELECT_GOOD';

// Action Creators
export const requestGoods = createAction(REQUEST_GOODS);
export const receiveGoods = createAction(RECEIVE_GOODS);
export const emptyGoods = createAction(EMPTY_GOODS);
