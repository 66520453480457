import * as R from 'ramda';
import {handleActions} from 'redux-actions';
import {RECEIVE_PROMOCODE, REMOVE_PROMOCODE, REQUEST_PROMOCODE, REQUEST_PROMOCODE_FAILED} from "./actions";
import {combineReducers} from "redux";

const enteredCode = handleActions({
  [REMOVE_PROMOCODE]: () => null,
  [REQUEST_PROMOCODE]: (_, {payload}) => payload
}, null);

const isFetching = handleActions(
  {
    [REQUEST_PROMOCODE]: R.T,
    [RECEIVE_PROMOCODE]: R.F,
    [REQUEST_PROMOCODE_FAILED]: R.F
  },
  false,
);

const entry = handleActions(
  {
    [REMOVE_PROMOCODE]: () => null,
    [RECEIVE_PROMOCODE]: (_, {payload}) => payload
  },
  null,
);

const error = handleActions(
  {
    [REMOVE_PROMOCODE]: () => null,
    [REQUEST_PROMOCODE]: () => null,
    [REQUEST_PROMOCODE_FAILED]: (_, {payload}) => payload
  },
  null,
);
export default combineReducers({
  enteredCode,
  isFetching,
  entry,
  error
});
