import React, {useEffect} from 'react';
import b from './Modal.module.scss'
import {connect} from "react-redux";
import * as R from "ramda";
import {getOrderId, getOrderName, getOrderType, resetOrder} from "../../state/order";
import {removePromocode} from "../../state/promocode";

const ModalAlert = () => {
  useEffect(() => {
    document.body.classList.add('modal-active');
    return () => {
      document.body.classList.remove('modal-active');
    }
  }, []);

  return (
    <div className={b.modal}>
      <div className={b.modalInner}>
        <div className={b.modalHeadline}>
          Сервис временно <br/> недоступен
        </div>
        <div className={b.modalContent}>
          <p>Оформление и приём заказов приостановлены из-за технического сбоя в программном обеспечении компании СДЭК</p>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  resetOrder,
  removePromocode,
};

const mapStateToProps = R.applySpec({
  orderId: getOrderId,
  orderType: getOrderType,
  name: getOrderName,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalAlert);
