import * as R from 'ramda';
import {handleAction, handleActions} from 'redux-actions';
import {combineReducers} from "redux";
import {RECEIVE_TEAMS, REQUEST_TEAMS, SELECT_SPORT_TYPE, SELECT_TEAM} from "./actions";

const entities = handleAction(
  RECEIVE_TEAMS,
  (state, {payload}) => R.uniqBy(R.prop('id'), [...payload, ...state]),
  [],
);

const selectedSportTypeId = handleAction(
  SELECT_SPORT_TYPE,
  (_, {payload}) => payload,
  null,
);

const isFetching = handleActions(
  {[REQUEST_TEAMS]: R.T, [RECEIVE_TEAMS]: R.F},
  false,
);

const selectedId = handleAction(SELECT_TEAM, (_, {payload}) => payload, null);

export default combineReducers({
  entities,
  selectedSportTypeId,
  isFetching,
  selectedId
});
